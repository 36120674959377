.publicationsHeader {
    padding: 30px 0 48px;
}

.publicationsHeaderText {
    font-family: 'Poppins', sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0.35em;
    text-align: center;
}

.publications {
    padding: 0 40px 100px;
}

.publicationYearText {
    font-family: 'Poppins', sans-serif;
    font-size: 19px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0.05em;
    
}

.publicationCitationText {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.05em;
    text-align: left;    
}

@media only screen and (max-width: 1024px) {
    .publicationsHeader {
        padding-top: 100px;
    }

    .publicationsHeaderText {
        font-size: 50px;
    }

    .publicationYearText {
        font-size: 42px;
    }

    .publicationCitationText {
        font-size: 38px;
        line-height: normal;
    }
}