.mapContainer {
    display: flex;
    flex-direction: column;
    padding: 100px;
}

.map {
    width: 100%;
    height: 479px;
}

.mapAddress {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
}

.mapAddressHeader {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.07em;
    text-align: center;
    color: rgba(33, 51, 108, 1);

}

.mapAddressText {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 17px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.07em;
    text-align: center;
    color: rgba(33, 51, 108, 1);
}

@media only screen and (max-width: 1024px) {
    .mapAddressHeader {
        font-size: 30px;
        line-height: normal;
    }

    .mapAddressText {
        font-size: 26px;
        line-height: normal;
    }
}