.projectsDiv {
    padding: 0 0 200px;
}

.projectItemLeft {
    display: flex;
    background: linear-gradient(245.86deg, #1b3571 26.48%, #5e1532 93.72%);
    width: 170px;
    height: 152px;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 16px;
}

.projectDetailContainer {
    padding-bottom: 100px;
}

.projectItemImg {
    width: 90%;
    height: auto;
}

.projectItemHeader {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0.05em;
    color: rgba(66, 103, 244, 1);
}

.projectItemHeadText {
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.05em;
}

.projectItemIRNText {
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.05em;
}

.projectDetailSubheaderText {
    padding: 0px 42px 20px;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0.05em;
    text-align: left;
}

.projectDetailDescriptionText {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 300;
    line-height: 26px;
    letter-spacing: 0.05em;
    text-align: left;
    white-space: pre-wrap;
    padding: 0px 42px 20px;
}

.projectItemMoreButtonLink {
    text-decoration: none;
}

.projectItemMoreButtonText {
    padding: 6px 23px;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.05em;
    background: rgba(28, 54, 114, 1);
    border-radius: 10px;
    text-align: center;
    color: white;
    border: none;
}
