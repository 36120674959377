.homeWrapper {
  display: flex;
  flex-direction: column;
}

.header {
  /* text-transform: uppercase;
    margin: 0 0 20px 0;
    font-weight: 800;
    font-size: 36px;
    color: white;
    text-align: center; */
}

.homeWrapper h3,
h4,
h5 {
  color: white;
}

.homeBanner {
  display: flex;
  height: 100vh;
  width: 100%;
  background-image: url("../../media/output-onlinegiftools.gif");
  color: white;
  text-align: center;
  background-size: 100%;
  background-position: 100% 10%;

  /* The image doesn't repeat */
  background-repeat: scroll;

  /* Fix the background */
  background-attachment: fixed;
  padding-left: 20px;
  padding-right: 20px;
}

.homeBannerMobile {
  display: flex;
  height: 100vh;
  width: 100%;
  background-image: url("../../media/2023-09-11\ 01.15.44.jpg");
  color: white;
  text-align: center;
  background-size: 100%;
  background-position: center;

  /* The image doesn't repeat */
  background-repeat: scroll;

  /* Fix the background */
  background-attachment: fixed;
  padding-left: 20px;
  padding-right: 20px;
}

.homeBannerOverlay {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
}

.searchBar {
  width: 630px;
  height: 40px;

  margin-top: 100px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.searchBarMain {
  display: flex;
  flex-direction: row;
  background-color: rgba(251, 251, 251);
  padding-left: 15px;
  padding-right: 15px;
}

.searchInput {
  border: none;
  width: 500px;
  height: 40px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.1em;
  text-align: left;
  padding-left: 30px;
}

.searchInput:focus {
  outline: none;
}

.searchResults {
  position: absolute;
  display: flex;
  background: rgba(255, 255, 255, 1);
  width: 630px;
  height: max-content;
  border-radius: 0 0 10px 10px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 90px;
  margin-top: 40px;
}

.searchResult {
  display: flex;
  flex-direction: row;
  padding: 10px 0 10px;
  padding-left: 15px;
  padding-right: 15px;
}

.searchResultLink {
  text-decoration: none;
}

.searchResultText {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.05em;
  text-align: left;
  color: gray;
  padding-left: 30px;
}

.homeBannerHeader {
  color: #fff;
  font-size: 96px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 150px;
  margin-bottom: 10px;
}

/* .animate-charcter {
  background-image: linear-gradient(
    225deg,
    #231557 0%,
    #44107a 29%,
    #e24e7f 67%,
    rgb(255, 255, 255) 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 4s linear infinite;
   display: inline-block; 
}

@keyframes textclip {
  from {
    background-position: 200% center;
  }
  to {
    background-position: 0 center;
  }
} */

@keyframes blinkingText {
  0% {
    text-shadow: 0 0 10px #ffffff;
  }
  50% {
    text-shadow: 0 0 20px #ffffff;
  }
  100% {
    text-shadow: 0 0 10px #ffffff;
  }
}

.blinking-text {
/* Установите основной стиль вашего текста */

/* Подключите анимацию с заданным временем и повторением */
animation: blinkingText 1s infinite;
}

/* Также вы можете добавить свойства для более плавного мигания */
.blinking-text {
animation-timing-function: ease-in-out;
}



.homeBannerText {
  margin-top: 40px;
  color: #fff;
  font-size: 24px;
  font-weight: 200;
  line-height: 30px;
  margin: 0 auto;
  margin-bottom: 40px;
  width: 90%;
}

.homeEmployees {
  padding: 100px;
}

.homeEmployeesHeader {
  text-align: center;
  background: linear-gradient(245.86deg, #1b3571 26.48%, #5e1532 93.72%);
}

.mapMain {
  display: flex;
  flex-direction: сolumn;
}

.mapContacts {
  display: flex;
  flex-direction: column;
}

.contact {
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.contactIcon {
  height: 30px;
  width: auto;
}

.contactText {
  padding-left: 5px;
}

.homeMainNews {
  padding: 100px;
}

.newsUnderHeader {
  display: flex;
  flex-direction: row;
}

.arrow {
  padding: 0 10px;
  height: 20px;
  width: auto;
}

.latestNews {
  display: flex;
  flex-direction: row;
}

.latestNewsItem {
  display: flex;
  flex-direction: row;
}

.otherNews {
  display: grid;
  grid-template-columns: auto auto;
}

.otherNewsItem {
  width: 90%;
}

.homeMainMap {
  padding: 100px;
}

.contactUs {
  padding: 100px;
}

.contactUsTop {
  display: flex;
  flex-direction: row;
}

.socialLinks {
  display: flex;
  flex-direction: row;
}

.socialIcon {
  height: 35px;
  width: auto;
}

.contactUsNameEmail {
  display: flex;
  flex-direction: row;
}

.contactUsInput {
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.42857143;
  color: #444;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ddd;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}

.contactUsEmail {
  padding-left: 20px;
  width: 300px;
}

.contactUsMessage {
  padding-top: 15px;
  padding-bottom: 30px;
}

.contactUsButton {
  margin: 30px 0;
  background: transparent;
  border: 2px solid #fff;

  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  color: #fff;
  background-color: #5ca9fb;
  background-image: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  padding: 14px 34px;
  letter-spacing: 1px;
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  border-radius: 10px;
  transition: all 0.5s linear;
  border: 0;
}

@media only screen and (max-width: 1024px) {
  .searchBar {
    width: 90%;
    margin-top: 30%;
  }
  .searchInput {
    width: 100%;
    height: 60px;
  }

  .homeBanner {
    height: 70vh;
  }

  .homeBannerText {
    line-height: normal;
    font-size: 55px;
  }
}
