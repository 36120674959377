.footerContainer {
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 88px;
    background: linear-gradient(245.86deg, #1B3571 26.48%, #5E1532 93.72%);
    border-top: 1px solid (255, 255, 255, 1);
    padding: 0 26px;
    justify-content: space-between;
    align-items: center;
}

.footerCenter {
    padding: 0 26px;
}

.footerBorder {
    border-left: 0.5px solid rgb(255, 255, 255, 1);
    border-right: 0.5px solid rgb(255, 255, 255, 1);
    height: 100%;
}

.footerLeft {
    margin-left: 40px;
}

.footerRight {
    margin-right: 40px;
}

.footerText {
    font-family: 'Poppins', sans-serif;
    font-size: 11px;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0.15em;
    text-align: left;
    color: white;
    text-align: center;

}

@media only screen and (max-width: 1024px) {
    .footerContainer {
        display: none;
    }
}