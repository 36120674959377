
.header {
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.headerTop {
    display: flex;
    flex-direction: row;
    height: 71px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: space-between;
    align-items: center;
}

.headerTopContacts {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 160px;
}

.headerTopContacts img:hover {
    width: 40px;
}

.headerLogoDiv {
    border-right: 3px solid white;
    padding-top: 14px;
    padding-right: 55px;
    padding-bottom: 14px;
}

.headerLogo {
    height: 87px;
    width: auto;
}

.headerTopLinks {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.headerTopLink {
    text-decoration: none;
    color: rgba(28, 54, 114, 1);
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
}

.headerLanguageButtons {
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
    justify-content: center;
}

.headerLanguageButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
}

.headerLanguageButtonText {
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0.03em;
    text-align: center;
    color: rgba(28, 54, 114, 1);
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    margin: 0;
}

.headerLanguageButtonText:hover {
    font-size: 24px;
}

.headerBot {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 83px;
    padding-left: 40px;
    width: 100%;
    height: max-content;
    background: linear-gradient(245.86deg, #1B3571 26.48%, #5E1532 93.72%);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.sticky {
    position: fixed;
    top: 0;
    z-index: 10;
    animation: smoothScroll 0.3s forwards;
}
@keyframes smoothScroll {
	0% {
		transform: translateY(-40px);
	}
	100% {
		transform: translateY(0px);
	}
}

.headerBotTabs {
    display: flex;
    flex-direction: row;
    padding-left: 76px;
    width: 100%;
    margin-top: 20px;
    list-style-type: none;
    justify-content: space-between;
}

.headerBotTab {
    text-decoration: none;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0.03em;
    text-align: left;

} 

.droptab {
    width: 100%;
    padding: 5px;
}

.droptab:hover {
    background: var(--Gray-3, #F3F5F6);
}

.droptab-link {
    transition: all linear .2s;
    touch-action: manipulation;
    text-decoration: none;
    color: black;
    text-align: left;
    font-style: normal;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 300;
    padding: 0 20px;
    width: 100%;
}

.droptab-link:hover {
    color: rgb(74, 5, 233);
}

@media only screen and (max-width: 1024px) {
    .headerBotTab {
        font-size: 16px;
    }

    .droptab-link {
        margin-top: 10px;
        margin-bottom: 10px;
        color: #6610f2;
        text-align: left;
        font-family: 'Poppins', sans-serif;
        font-size: 30px;
        font-weight: 300;
        
    }

    .headerTopContacts  {
        margin-top: 250px;
        justify-content: center;
        align-items: center;
    }

    .headerTopContacts img {
      
        height: 100px;
    }

    .headerTopLink {
        text-decoration: none;
        color: rgba(28, 54, 114, 1);
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 40px;
        font-weight: 600;
        text-align: center;
    }

    .headerLanguageButtons {
        justify-content: left;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    
    .headerLanguageButtonText {
        font-size: 30px;
    }

    .footerMobile {
        position: absolute;
        bottom: 40px;
    }

    .footerMobileText {
        color: black;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 35px;
        font-weight: 400;
    }
}