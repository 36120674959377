.files-container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    background-color: #f9f9f9;
    width: max-content;
    padding: 20px;
    margin-top: 60px;
}

.file-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .file-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .file-item:hover {
    background-color: #e9e9e9;
  }
  
  .file-icon {
    width: 60px;
    height: auto;
    font-size: 24px; /* Adjust icon size as needed */
    margin-right: 10px;
    color: #666;
  }
  
  .file-link {
    color: #333;
    text-decoration: none;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .file-link:hover {
    text-decoration: underline;
  }
  