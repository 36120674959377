.lab {
  padding: 100px 40px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.labHeaderText {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.05em;
  text-align: center;
}

.labMain {
  display: flex;
  flex-direction: row;
  gap: 30px;

}

.labMain p {
  color: #030305;
  margin: 5px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.6px;
}

.labMainPerson h3 {
  color: #131217;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 1.04px;
}

.labMainPerson {
  grid-column-start: 1;
  grid-row-start: 2;
  grid-row-end: 4;
  text-align: center; /* Center the text within the person div */
  color: #131217;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 1.04px;
}

.labActionButtons {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-self: flex-end;
}

.labActionButtons button {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 2.8px;
  border-radius: 10px;
  background: linear-gradient(246deg, #1b3571 26.48%, #5e1532 93.72%);
  padding: 10px;
}
