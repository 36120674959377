.projectsContainer {
    display: flex;
    flex-direction: column;
    background: linear-gradient(245.86deg, #1B3571 26.48%, #5E1532 93.72%);

}

.projectsHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 31px;
}

.projectsHeaderText {
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-weight: 900;
    line-height: 54px;
    letter-spacing: 0.15em;
    text-align: left;
    color: white;
}

.projectList {
    padding-top: 58px;
    width: 85%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    gap: 40px;
    justify-content: space-between;
}

.projectItem {
    width: 361px;
}

.projectItemTop {

    background: linear-gradient(245.86deg, #1B3571 26.48%, #5E1532 93.72%);
    display: flex;
    flex-direction: row;

    border-radius: 10px;
}

.projectIonosLogo {
    display: flex;
    padding-top: 10px;
    padding-left: 7px;
}


.projectIonosLogoImg {
    height: 40px;
    width: auto;
}

.projectLogo {
    display: flex;
    padding-left: 26px;
    padding-top: 38px;
    padding-bottom: 38px;
}

.projectLogoImg {
    height: 215px;
    width: auto;
}

.projectItemBot {
    padding-top: 36px;
}

.projectItemBotText {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 900;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-align: center;
}

.projectsButtonDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 44px 0 26px;
}

.projectsButton {
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    font-weight: 900;
    line-height: 20px;
    letter-spacing: 0.2em;
    text-align: center;
    padding: 6px 52px;
    background-color: white;
    border: none;
    color: rgba(38, 49, 104, 1);
    border-radius: 7px;

}

@media only screen and (max-width: 1024px) {

    .projectList {
        flex-direction: column;
        align-items: center;
    }

    .projectItemBotText {
        font-size: 30px;
        line-height: normal;
    }

    .projectsButton {
        font-size: 30px;
        line-height: normal;
    }
}