.homeNewsContainer {
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
}

.newsHeader {
    padding: 80px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.newsHeaderText { 
    font-size: 36px;
    font-weight: 900;
    line-height: 54px;
    letter-spacing: 0.15em;
    text-align: center;
    color: rgba(3, 3, 5, 1);
}

.newsHomeList {
    display: flex;
    flex-direction: row;
    gap: 30px;
    justify-content: center;
    padding-top: 60px;
}

.newsHomeItem {
    display: flex;
    flex-direction: column;
    width: 30%;
}

.newsItemTop {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: linear-gradient(245.86deg, #1B3571 26.48%, #5E1532 93.72%);
    height: 314px;
    border-radius: 15px;
}

.newsItemDate {
    padding-top: 13px;
    width: 45%;
}

.newsItemDateText {
    padding: 1px 23px;
    text-align: left;
    background-color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;
    letter-spacing: 0.25em;

    color: rgba(28, 54, 114, 1);
}

.newsHomeItemImgDiv {
    display: flex;
    align-items: center;
    justify-content: center;
}

.newsHomeItemImg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 295px;
    height: 200px;
    object-fit: contain; 
}

.newsItemButtonDiv {
    display: flex;
    align-items: end;
    justify-content: end;
    padding: 21px 0 14px;
    bottom: 10px;

}

.newsItemButton {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 900;
    line-height: 24px;
    letter-spacing: 0.25em;
    text-align: left;
    background-color: white;
    color: rgba(28, 54, 114, 1);
    border: none;
    padding: 1px 20px;
    bottom: 10px;

}

.newsItemHeader {
    padding-top: 14px;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0.05em;
    text-align: center;
}

.newsBot {
    padding-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.newsBotButton {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 900;
    line-height: 21px;
    letter-spacing: 0.2em;
    text-align: center;
    background-color: rgba(243, 38, 38, 1);
    color: white;
    padding: 9px 70px;
    border: none;
    border-radius: 1dvi;
}

@media only screen and (max-width: 1024px) {
    .newsHomeList {
        flex-direction: column;
        
    }

    .newsHomeItem {
        width: auto;
    }

    .newsItemDateText {
        font-size: 25px;
    }

    .newsItemHeader {
        font-size: 30px;
    }

    .newsBotButton {
        font-size: 30px;
    }
}