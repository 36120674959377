.staffBackground {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: linear-gradient(245.86deg, #1B3571 26.48%, #4C1F3D 45.38%), url("../../media/ionos_logo-white\ 1.png");
    background-blend-mode: overlay;
    padding-bottom: 320px;
}

.staffContainer {}

.staffHeaderText {
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 900;
    line-height: 36px;
    letter-spacing: 0.55em;
    text-align: center;
    color:white;
    padding: 42px 0 66px;
}


.staffList {
    display: flex;
    flex-flow: wrap row;
    padding: 0 90px;
    gap: 80px;
    align-items: center;
    justify-content: center;
}

.staffDetailPersonImg {
    height: 500px;
}

.link {
    text-decoration: none;
    color: black;
}

.employeePhoto {
    height: 207.2px;
    width: 150px;
    border-radius: 10px;
}

.staffDetail {
    display: flex;
    flex-direction: row;
    padding: 100px;
    padding-bottom: 180px;
    gap: 40px;
} 

.staffDetailRight {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.staffDetailRight h2 {
    margin: 0;
    font-weight: 600;
}

.staffDetailRightSection {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.staffDetailRightSection h6 {
    font-weight: 600;
}

.staffDetailRightContacts {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.staffItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.employeeNameText {
    padding-top: 17px;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.13em;
    text-align: center;
}

.employeePositionText {
    font-family: 'Poppins', sans-serif;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.13em;
    text-align: center;
    color: white;
}


.employeeParams {
    padding-left: 50px;
}

.employeeParam {
    display: flex;
    flex-direction: row;
}


.employeeIcon {
    height: 25px;
    width: auto;
    margin-right: 20px;
}

@media only screen and (max-width: 1024px) {
    .staffDetail {
        flex-direction: column;
        padding: 20px;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .staffDetailRight h2 {
        font-size: 58px;
    }

    .staffDetail p {
        font-size: 50px;
    }

    .staffDetailPersonImg {
        height: auto;

    }

    .employeeIcon {
        height: 65px;
    }

    .staffDetailRightSection {
        font-size: 50px;
    }

    .staffDetailRightSection ul {
        font-size: 50px;
    }

    .staffDetailRightSection h6 {
        font-size: 50px;
    }
}