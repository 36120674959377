.contactUsContainer {
    display: flex;
    flex-direction: column;
    padding: 0 100px 100px;
}


.contactUsHeader {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contactUsHeaderText {
    font-size: 36px;
    font-weight: 900;
    line-height: 54px;
    letter-spacing: 0.15em;
    text-align: center;
    color: rgba(3, 3, 5, 1);
}

.contactUsForm {
    padding-top: 20px;
}

.contactUsButtonDiv {

    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 1024px) {
    .contactUsInput {
        height: 80px;
        font-size: 36px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}