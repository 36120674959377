.experimentalBaseSecond {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 80px;
}

.experimentalBaseSecond p {
  margin: 0;
  text-align: center;
}

.experimentalBaseSecondMid {
  display: flex;
  flex-direction: row;
  gap: 50px;
}

.experimentalBaseSecondMidRight {
  color: #030305;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.experimentalBaseSecondMidLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.experimentalBaseSecondMid p {
  color: #030305;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.75px;
  margin: 0;
  text-align: left;
}

.experimentalBaseSecondBtm {
  color: #030305;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.75px;
}
