.administration {
    background: linear-gradient(245.86deg, #1B3571 26.48%, #5E1532 93.72%);
}

.administrationHeader {
    display: flex;
    justify-content: center;
    height: 158px;
}

.administrationHeaderText {
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: 0.15em;
    text-align: left;
    color: white;

    display: flex;
    align-items: center;
    justify-content: center;
}

.administrationList {
    display: flex;
    flex-direction: row;
    padding: 10px 100px 100px;
    gap: 10px;
}

.administrationItem {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    margin-top: 20px;
    width: 90%;
    height: 400px;
    align-items: center;
}

.administrationItemImgDiv {
    display: flex;
    width: 100%;
    background: linear-gradient(to bottom, rgba(250, 250, 250, 0) 20%, rgba(255, 255, 255, 0.12) 66%, rgba(255, 255, 255, 0.13) 70%);
    justify-content: center;
    align-items: center;
}

.administrationItemImg {
    height: 120px;
    width: 120px;
    object-fit: cover;
    border-radius: 50%;
    overflow: hidden;
    margin: 0rem auto 3rem;

}

.administrationItemDiv {
    background-color: rgba(255, 255, 255, 0.20);
    height: 300px;
    width: 100%;
    padding-top: 40px;
}


.administrationItemName {
    font-family: 'Poppins', sans-serif;
    font-size: 17px;
    font-weight: 900;
    line-height: 26px;
    letter-spacing: 0.13em;
    text-align: center;
}

.administrationItemPosition {
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0.13em;
    color: white;

    text-align: center;
}

@media only screen and (max-width: 1024px) {
    .administrationList {
        display: flex;
        flex-direction: column;
        gap: 30px;
  
    }

    .administrationItem {
        width: 100%;
    }

    .administrationItemName {
        font-size: 28px;
        line-height: normal;
    }

    .administrationItemPosition {
        font-size: 25px;
        line-height: normal;
    }
}