.technicalEquipmentFourth {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  margin-top: 100px;
}

.technicalEquipmentFourth h3 {
  color: #030305;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.9px;
}

.technicalEquipmentFourthTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.technicalEquipmentFourthBox {
    width: 460px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: #030305;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.75px;
}
