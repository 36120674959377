.carousel {
    display: flex;

    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    padding: 42px;
}

.carousel-images {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: transform 0.5s ease;
    height: auto;
    width: 300px;
}

.carousel-image {
    display: block;
    object-fit: cover;
    /* Optional: for better image scaling */
}

.carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    border: none;
    cursor: pointer;
    padding: 20px;
    z-index: 2;
}

.prev {
    left: 20px;
}

.next {
    right: 20px;
}