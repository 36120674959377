.technicalEquipmentSeventh {
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-top: 120px;

  color: #030305;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.75px;
}

.technicalEquipmentSeventh h3,
p {
  margin: 0;
}

.technicalEquipmentSeventh p {
  width: 300px;
  color: #030305;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.75px;
}

.technicalEquipmentSeventh h3 {
  color: #030305;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.1px;
  text-decoration-line: underline;
}

.technicalEquipmentSeventhMain {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 0 auto;
  align-items: center;
}
