.partnersContainer {
    display: flex;
    flex-direction: column;
    padding: 40px 100px 150px;
}

.partnersHeader {
    padding-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.partnersHeaderText {
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 800;
    line-height: 54px;
    letter-spacing: 0.15em;
    text-align: center;
    color: rgba(3, 3, 5, 1);
}

.partnersList {
    display: flex;
    flex-direction: row;
    padding-top: 40px;
    gap: 60px;
    overflow: hidden;
}

.partnersItem {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s linear;
    width: 200px;
}

.partnersItemImg {
    width: 200px;
}