.aerospaceMonitoring {
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  width: 94%;
  margin: 0 auto;
  padding-bottom: 200px;
}

.aerospaceMonitoring h2 {
  color: #030305;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 8.4px;
  text-align: center;
}

.aerospaceMonitoringMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  color: #030305;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.75px;
}

.aerospaceMonitoringMain h3 {
  color: #030305;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1px;
}

.aerospaceMonitoringMain img {
    width: 1068px;
}
