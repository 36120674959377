.technicalEquipmentSecond {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 120px;
  margin-bottom: 120px;
  gap: 30px;
}

.technicalEquipmentSecond h3,
p {
  margin: 0;
}

.technicalEquipmentSecond h3 {
  color: #030305;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
}

.technicalEquipmentSecond p {
  color: #030305;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.75px;
}

.technicalEquipmentSecondMain {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.technicalEquipmentSecondMainBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  color: #030305;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.7px;
}
