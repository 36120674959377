/* Geomagnetic Dimensions*/
.geomagneticDimensions {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 100px 40px;
  gap: 40px;
}

.geomagneticDimensionsMain {
  display: flex;
  flex-direction: column;
}

.geomagneticDimensionsMainImages {
  display: grid;
  grid-template-columns: auto auto;
}

.geomagneticDimensions h2 {
  margin: 0;
  color: #030305;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 8.4px;
  text-align: left;

}

.geomagneticDimensionsMain h3 {
  margin: 0;
  color: #030305;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1px;
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: left;
}

.geomagneticDimensionsMainTxt {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.geomagneticDimensionsMainTxt h4 {
  margin: 0;
  color: #030305;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.65px;
  text-align: left;
}
